@import url("https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.ic_navbar_nav .ic_nav_link {
  position: relative;
  font-size: 14px;
  color: #103935;
  height: 30px;
  display: flex;
}
.ic_navbar_nav .ic_nav_link:hover {
  position: relative;
  font-size: 14px;
  color: #ff9500;
}

.ic_navbar_nav .ic_nav_link.active {
  color: #ff9500;
  font-weight: 700;
}

.ic_navbar_nav .ic_nav_link::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #ff9500;
  width: 0%;
  height: 2px;
  transition: all 0.3s linear;
}

.ic_navbar_nav .ic_nav_link.active::before,
.ic_navbar_nav .ic_nav_link:hover:before {
  width: 100%;
  transition: all 0.3s linear;
}
.swiper {
  --swiper-navigation-size: 20px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 20px;
  --swiper-navigation-color: #071524;
  --swiper-pagination-color: #c21e56;
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 95px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

/* .ant-checkbox-checked .ant-checkbox-inner .ant-checkbox {
  background-color: #fabf55;
  border-color: #cd8e42;
  height: 20px;
  width: 20px;
} */

.ant-checkbox {
  height: 20px !important;
  width: 20px !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  /* border-color: #1890ff; */
  border-color: #cd8e42 !important;
  /* background-color: #fabf55 !important; */
  height: 20px;
  width: 20px;
}
@media screen and (max-width: 500px) {
  .swiper {
    --swiper-pagination-bottom: 80px;
  }
}

.waveform {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls label {
  margin-right: 10px;
}

.controls input {
  width: 60px;
  text-align: center;
}
.scrollBar-color {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    margin-bottom: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 180px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff9500;
    border-radius: 180px;
  }
}

.scrollBar-color1 {
  &::-webkit-scrollbar {
    width: 8px;
    margin-bottom: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 180px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff9500;
    border-radius: 180px;
  }
}
.checkbox-wrapper-65 *,
.checkbox-wrapper-65 ::after,
.checkbox-wrapper-65 ::before {
  box-sizing: border-box;
}

.checkbox-wrapper-65 .cbx {
  position: relative;
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #f2994a;
  background-image: linear-gradient(#fff, #fff);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.15),
    inset 0 -1px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.15s ease;
}

.checkbox-wrapper-65 .cbx svg {
  position: absolute;
  top: 3.4px;
  left: 3.4px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  stroke-width: 2;
  stroke-dasharray: 17;
  stroke-dashoffset: 17;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-65 .cbx + span {
  float: left;
  margin-left: 6px;
}

.checkbox-wrapper-65 {
  user-select: none;
}

.checkbox-wrapper-65 label {
  display: inline-block;
  cursor: pointer;
}

.checkbox-wrapper-65 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-65 input[type="checkbox"]:checked + .cbx {
  background-color: #fff;
  background-image: linear-gradient(#f2994a, #f2994a);
}

.checkbox-wrapper-65 input[type="checkbox"]:checked + .cbx svg {
  stroke-dashoffset: 0;
  transition: all 0.15s ease;
}

canvas {
  display: block;
  position: relative;
}

.heading-font {
  font-size: clamp(18px, 4vw, 36px);
}

/* .ant-picker-input>input::placeholder {
  color: red;
} */

html {
  -webkit-user-select: none;
  user-select: none;
}

/* CSS */
.button-85 {
  padding: 0.3em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* CSS */
.button-87 {
  padding: 0.3em 2em;
  transition: 0.5s;
  background-size: 200% auto;
  border-radius: 10px;
  border: 0px;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(
    45deg,
    #ff0000 0%,
    #ff0000 51%,
    #ff0000 100%
  );
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}
